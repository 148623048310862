<template>
  <v-container
    :fluid="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false"
  >
    <v-row class="pb-5 align-center">
      <v-col cols="auto">
        <v-btn
          @click="$router.go(-1)"
          plain
          :ripple="false"
          color="accent"
          small
          outlined
          icon
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <h2 class="headline font-weight-medium">
          {{ $t("Future-Self Journal") }}
        </h2>
      </v-col>
    </v-row>
    <div
      class="d-flex flex-column fill-height align-center justify-space-between py-15"
      v-if="journalIsNotShared"
    >
      <h2>{{ $t("Ooops!") }}</h2>
      <h3>Journal not Shared</h3>
    </div>

    <Journal
      v-else
      :loading="loading"
      :items="journalItems"
      :journal="journal"
      :headers="headers"
      @sharedChange="onSharedChange"
      @saveItem="onSaveItem"
      @deleteItem="onDeleteItem"
    />
  </v-container>
</template>

<script>
import Journal from "@/components/Common/JournalV2";
import UserMixin from "@/mixins/UserMixin";
import { mapActions } from "vuex";

export default {
  name: "InteractiveJournal",
  mixins: [UserMixin],

  components: {
    Journal,
  },

  data: () => {
    return {
      journalItems: [],
      journal: null,
      loading: false,
      journalIsNotShared: false,
    };
  },

  computed: {
    myJournal() {
      return this.$route.params.userId === this.me._id;
    },

    headers() {
      let headers = [
        {
          text: this.$t("Date"),
          align: "start",
          sortable: true,
          value: "createdAt",
        },

        // { text: this.$t('Title'), value: 'title', sortable: false },
        {
          text: this.$t("My daily affirmation"),
          value: "affirmation",
          sortable: false,
        },
        {
          text: this.$t("My intention for today is"),
          value: "intention",
          sortable: false,
        },
        {
          text: this.$t("I will focus on"),
          value: "focusOn",
          sortable: false,
        },
        {
          text: this.$t("First action"),
          value: "firstAction",
          sortable: false,
        },
        {
          text: this.$t("Second action"),
          value: "secondAction",
          sortable: false,
        },
        {
          text: this.$t("Third action"),
          value: "thirdAction",
          sortable: false,
        },
        {
          text: this.$t("I will work on letting go of"),
          value: "workOn",
          sortable: false,
        },
        {
          text: this.$t("I am looking forward to"),
          value: "lookingForward",
          sortable: false,
        },
        {
          text: this.$t("I am grateful for"),
          value: "grateful",
          sortable: false,
        },
        {
          text: this.$t("Actions"),
          value: "actions",
          sortable: false,
        },
        // { text: this.$t('Journal'), value: 'text', sortable: false },
      ];

      // if (this.myJournal) {
      //   headers.push();
      // }

      return headers;
    },
  },

  methods: {
    ...mapActions("journal", [
      "getMyJournal",
      "getUserJournal",
      "createJournalMessage",
      "updateJournalMessage",
      "removeJournalMessage",
      "setShared",
    ]),

    async onSharedChange(val) {
      this.loading = true;
      await this.setShared({
        id: this.journal._id,
        setSharedData: { shared: !!val },
      });
      this.journal.shared = val;
      this.loading = false;
    },

    async onSaveItem(item, realItem) {
      let data = {
        // title: item.title,
        // text: item.text,
        affirmation: item.affirmation,
        intention: item.intention,
        focusOn: item.focusOn,
        firstAction: item.firstAction,
        secondAction: item.secondAction,
        thirdAction: item.thirdAction,
        workOn: item.workOn,
        lookingForward: item.lookingForward,
        grateful: item.grateful,
      };

      if (item._id) {
        const updateData = data;
        await this.updateJournalMessage({ id: item._id, updateData });
        // realItem.title = data.title;
        // realItem.text = data.text;
        realItem.affirmation = data.affirmation;
        realItem.intention = data.intention;
        realItem.focusOn = data.focusOn;
        realItem.firstAction = data.firstAction;
        realItem.secondAction = data.secondAction;
        realItem.thirdAction = data.thirdAction;
        realItem.workOn = data.workOn;
        realItem.lookingForward = data.lookingForward;
        realItem.grateful = data.grateful;
      } else {
        const createData = data;
        const res = await this.createJournalMessage({ createData });
        this.journalItems.push(res);
      }
    },

    async onDeleteItem(item) {
      if (item._id) {
        await this.removeJournalMessage({ id: item._id });
        const index = this.journalItems.findIndex((e) => e._id === item._id);
        if (index !== -1) {
          this.journalItems.splice(index, 1);
        }
      }
    },

    async getJournal() {
      this.loading = true;

      try {
        let res;
        if (this.myJournal) {
          res = await this.getMyJournal();
        } else {
          const id = this.$route.params.userId;
          res = await this.getUserJournal({ id });
        }

        this.journal = res.journal;
        this.journalItems = res.messages;
      } catch (e) {
        console.log("e.response: ", e.response);
        if (e.response.status === 400 && e.response.data.notShared) {
          this.journalIsNotShared = true;
        }
      }

      this.loading = false;
    },
  },

  mounted() {
    this.loading = true;
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        await this.getJournal();
      },
    });
  },
};
</script>
